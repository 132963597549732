import React, { useState } from 'react'
import { Link } from "react-router-dom";


const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  const [ismobile, setIsmobile] = useState(0);

  // const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };

  return (
    <>
      {/* <div className="main">
        <div className="marq" bgcolor="" direction="left" loop>
          <div className="geek2">
            <b style={{ color: "#007abd" }}>Open House: September 06th FROM 2 TO 5 PM</b>
          </div>
        </div>
      </div> */}

      
      <section>
        <nav className="navbar navbar-expand-lg main_menu">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="./assets/images/Aqta_Logo__Final-03-removebg-preview.png" alt="Eduor" className="img-fluid" style={{ width: "100%" }} />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <i className="far fa-bars menu_icon" />
              <i className="far fa-times close_icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/" onClick={closemenu}>Home <i className="" /></Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us" >about us <i className="far fa-angle-down" /></Link>
                  <ul className="tf__droap_menu">
                    <li><Link to="/about-us" onClick={closemenu}>Who we are</Link></li>
                    {/* <li><Link to="/" onClick={closemenu}>Staff</Link></li> */}
                    <li><Link to="/faq" onClick={closemenu}>FAQs</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">Apply Now <i className="far fa-angle-down" /></Link>
                  <ul className="tf__droap_menu">
                    {/* <li><Link to="/" onClick={closemenu}>Admissions</Link></li> */}
                    <li><a href='./assets/images/AQTA-Tuition Fee - 2024-25.pdf' target="_blank" onClick={closemenu}>Tuition and Fees</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/programs" onClick={closemenu}>
                    Programs</Link>
                </li>

                {/* <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={closemenu}>
                    Portal</Link>
                </li> */}
             

                <li className="nav-item">
                  <Link className="nav-link" to="/">Careers <i className="far fa-angle-down" /></Link>
                  <ul className="tf__droap_menu">
                    <li><a href='./assets/images/AQTA - Pre-K-Assistant Teacher.pdf' target="_blank" onClick={closemenu}>Pre-K-Assistant Teacher</a></li>
                    <li><a href='./assets/images/AQTA - Pre-K-Lead Teacher.pdf' target="_blank" onClick={closemenu}>Pre-K-Lead Teacher</a></li>
                  </ul>
                </li>
                <li className="nav-item" >
                  <Link className="nav-link" onClick={closemenu} to="/contact-us">contact Us </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/">Login <i className="far fa-angle-down" /></Link>
                  <ul className="tf__droap_menu">
                    <li><a href='https://schoolpro.azurewebsites.net/' target="_blank" onClick={closemenu}>Parent</a></li>
                    <li><a href='https://schoolpro.azurewebsites.net/account/student' target="_blank" onClick={closemenu}>Student</a></li>
                  </ul>
                </li>

                <li className="nav-item" onClick={closemenu}>
                <a className="nav-link common_btn" style={{color:"#007abd", padding:"10px 11px", fontSize:"13px"}} onClick={closemenu} href='https://us.mohid.co/nc/durham/jamaat/masjid/online/vfr/campaign/al_qalam_triangle_academy' target="_blank">Donate now</a>
              </li>
              {/* old */}
                {/* <li className="nav-item" onClick={closemenu}>
                <a className="nav-link common_btn" style={{color:"#007abd", padding:"10px 11px", fontSize:"13px"}} onClick={closemenu} href='https://us.mohid.co/nc/durham/jamaat/masjid/online/vfr/campaign/al_qalam_triangle_academy' target="_blank">Donate now</a>
              </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </section>
      

    </>
  )
}

export default Hadear