import React from 'react'
import { Link } from "react-router-dom";


const HomeBanner = () => {
    return (
        <>

            

            <section className="tf__banner mt-lg-5" style={{ background: 'url(./assets/images/muslim-girls.jpg)', overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8">
                            <div className="tf__banner_text wow fadeInUp" data-wow-duration="1.5s">
                                <h5 style={{ color: "white", textShadow: "0px 0px #e5bf20", textAlign:"" }} ><b>Open House: September 06th from 2 TO 5 PM</b> </h5>
                                <h1 style={{ color: "white", textShadow: "3px 2px black" }}>Together   <span style={{ textShadow: "0.5px 0.5px white" }}>Towards</span> Education.</h1>
                                {/* <h5 style={{ color: "white", textShadow: "0px 0px #e5bf20" }} ><b>Welcome to witness an Exceptional Islamic School Experience</b> </h5> */}
                               
                                <p style={{ color: "white", textShadow: "2px 2px black" }}> <b>Welcome to witness an Exceptional Islamic School Experience.</b></p>
                                <ul className="d-flex flex-wrap align-items-center">
                                    <li><a className="common_btn" href="https://schoolpro.azurewebsites.net/Application/StudentRegistration/14212"  target="_blank" style={{fontSize:"12px"}}>Enroll Now</a></li>
                                    {/* <li><a className="common_btn" href="https://us.mohid.co/nc/durham/jamaat/masjid/online/registration/index/ZXF1RytHdDhWMUsvU0JxSmZXSmY0dz09"  target="_blank" style={{fontSize:"12px"}}>Enroll Now</a></li> */}
                                    <li><a className="common_btn" href="https://us.mohid.co/nc/durham/jamaat/masjid/online/vfr/campaign/al_qalam_triangle_academy" target="_blank">Donate now</a></li>

                                    {/* <li>
                                        <a className="venobox play_btn" data-autoplay="true" data-vbtype="video" to="https://youtu.be/xsnCYCEbdr4">
                                            <i className="fas fa-play" />
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeBanner