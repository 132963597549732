import React from 'react'
import HomeArabicCurriculum from '../home/HomeArabicCurriculum'
import Footer from '../../component/layout/Footer'
import HomePrograms from '../home/HomePrograms'

const Programs = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <HomePrograms />
            <section className="tf__about_us_page mt_195 xs_mt_100" style={{ overflow: "hidden" }}>
                <div className="tf__faq mt_100 pt_95 pb_100" style={{ background: 'url(./assets/images/faq_bg.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                                <div className="tf__faq_img">
                                    <img src="./assets/images/Al-Qalam_4.webp" alt="faqs" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                                <div className="tf__faq_text">
                                    <div className="tf__heading_area tf__heading_area_left mb_25">
                                        <h5 style={{ textAlign: "center" }}><b>Kindergarten</b></h5>

                                    </div>
                                    <div className="tf__faq_accordion">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item orange">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        English
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 By expanding on their innate curiosity and prior knowledge, students will gain proficiency in reading, writing, and oral language. They will demonstrate phonological awareness and comprehend writing in English. They will efficiently decode and employ new vocabulary in reading and writing by utilizing morphological analysis, spelling patterns, and letter-sound correlations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item green">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Math
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 Basic topics related to numbers and operations will be taught to students. In order to comprehend the connection between numbers and quantities, they will be able to apply counting rules, count sequences, and name numbers. They will learn addition and subtraction problem-solving techniques and compare things according to quantifiable characteristics. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item orange">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Science
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p> 🔹 Students will learn how to conduct scientific research by observing and describing the natural world using their senses. They will increase their vocabulary by learning about the characteristics of things, materials found on Earth, and living things. They will engage in safe classroom and outdoor research as well as environmentally conscious behavior.</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item green">
                                                <h2 className="accordion-header" id="headingThree1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                        Social Studies
                                                    </button>
                                                </h2>
                                                <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 Students will investigate individual contributions, patriotic holidays, and state and national heritage. They will study human needs, geography, and historical events. They will practice problem-solving and decision-making techniques and gain an understanding of the purpose of rules and the function of authority figures.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br />
            <br />
            <br />
            <br />
            <br />
            <section className="tf__about mt_250 xs_mt_195 mt-lg-5" >
                <div className="container">
                    <div className="tf__about_top wow fadeInUp" data-wow-duration="1.5s" style={{ background: 'url(./assets/images/about_top_bg.jpg)' }}>
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="tf__about_top_img">
                                    <img src="./assets/images/img_1.jpg" alt="about" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="tf__about_top_text">
                                    <div className="tf__about_top_text_center">
                                        {/* <h5 style={{color:"white", textAlign:"center"}}>Family Portal</h5> */}

                                        <h4>Afterschool Programs</h4>
                                        <p>Afterschool programs are conducted after 3pm and the Academy partners with various youth based organizations that offer extracurricular activities. For an overview of our current after school program,</p>
                                    </div>
                                    <a to="#" className="common_btn">click here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf__event_details" style={{marginTop:"-80px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{textTransform:"capitalize"}}> Islamic Education </span>
                                {/* <h3>Al-Qalam Triangle Academy Education:</h3> */}

                                <p className='mt-lg-4'>Our goal is to give our young students a solid foundation in worship and Qur'anic memorization. We have established guidelines designed especially for young children, teaching them to value their faith and develop a relationship with God via prayer and courses based on faith that are integrated into the curriculum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HomeArabicCurriculum />
            <br/>
            <br/>
            <Footer />
        </>
    )
}

export default Programs