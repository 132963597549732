import React, { useState } from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';
import Footer from '../../component/layout/Footer';
import emailjs from "@emailjs/browser";




const ContactUs = () => {

    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
  
    const nameChange = (e) => {
      const value = e.target.value;
      setName(value);
    };
 
    const mailChange = (e) => {
      const value = e.target.value;
      setmail(value);
    };
    const massagesChange = (e) => {
      const value = e.target.value;
      setMassages(value);
    };

  
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm(
         
          "service_oumku8k",
          "template_esy4tqm",
          "#myForm",
          "8i7i_APkS7sZ9CqPl"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            if (response.text === "OK") {
              setName("");
              setmail("");
              setMassages("");
            
            }
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    };
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                {/* <section className="tf__breadcrumb" style={{ background: 'url(./assets/images/breadcrumb_bg_1.jpg)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="tf__breadcrumb_text">
                                    <h2>contact us</h2>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><a to="">contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="tf__contact_page mt_190 xs_mt_95">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-xl-7 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s" style={{ marginTop: "-82px" }}>
                                <div className="tf__contact_form">
                                    <div className="tf__heading_area tf__heading_area_left mb_25">
                                        {/* <h5> Contact Us</h5> */}
                                        <h2>Contact Us</h2>
                                    </div>

                                    <p>If you are interested in learning more about Al-Qalam Triangle Academy or have any questions, please fill out the form below.</p>
                                    <form method="post" onSubmit={sendEmail} id="myForm">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <input type="text" name="from_name" placeholder="Name" 
                                                onChange={(e) => nameChange(e)}
                                                required
                                                value={name}
                                                />
                                            </div>
                                            <div className="col-xl-6">
                                                <input type="email" placeholder="Email" 
                                                 onChange={(e) => mailChange(e)}
                                                 value={mail}
                                                 required
                                                />
                                            </div>
                                            <div className="col-xl-12">
                                                <textarea rows={2} placeholder="Message" 
                                                 onChange={(e) => massagesChange(e)}
                                                 value={massages}
                                                 required
                                                />
                                                
                                                <button type="submit"  value="submit" name='submit' className="common_btn_2 tej" style={{ background: "#1774ba", color: "white", fontSize:"18px"}}>SEND </button>
                                                   
                                              
                                            </div>
                                           
                                        </div>
                                    </form>


                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-5 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                                <div className="tf__contact_text">
                                    <div className="tf__contact_single">
                                        <div className="icon blue">
                                            <i className="fas fa-phone-alt" />
                                        </div>
                                        <div className="text">
                                            <h3>Call</h3>
                                            <a href="tel:+1 919-321-8485">+1 919-321-8485</a>
                                            {/* <a style={{ color: "black" }} href="https://chat.whatsapp.com/IWKFYcPcLkM3v0OKA4J7jr" target="_blank"> WhatsApp Join Group </a> */}
                                            {/* <a style={{ color: "black" }} href="https://wa.me/+1 919-475-5172" target="_blank"> WhatsApp : +1 919-475-5172 </a> */}
                                        </div>
                                    </div>
                                    <div className="tf__contact_single">
                                        <div className="icon orange">
                                            <i className="fa fa-whatsapp"></i>
                                        </div>
                                        <div className="text">
                                            {/* <h3>WhatsApp</h3> */}
                                            <a href="https://chat.whatsapp.com/IWKFYcPcLkM3v0OKA4J7jr" style={{color:"black"}} target="_blank"> WhatsApp-Join Group</a>
                                        </div>
                                    </div>
                                    <div className="tf__contact_single">
                                        <div className="icon orange">
                                            <i className="fas fa-envelope"></i>
                                        </div>
                                        <div className="text">
                                            <h3>mail</h3>
                                            <a href="mailto:admin@aqta.org">admin@aqta.org</a>
                                        </div>
                                    </div>
                                    <div className="tf__contact_single">
                                        <div className="icon green">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div className="text">
                                            <h3>Address</h3>
                                            <a href='https://maps.app.goo.gl/LVJyrMATMz7tpnHu9' target="_blank"><p>3034 Fayetteville St, Durham, NC 27707</p></a><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-lg-4' style={{ maxWidth: '100%', listStyle: 'none', transition: 'none', overflow: 'hidden', width: 1200, height: 400 }}><div id="my-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=3034+Fayetteville+St+Durham,+NC+27707&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="our-googlemap-code" href="https://www.bootstrapskins.com/themes" id="enable-maps-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#my-map-canvas .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} /></div>


                            {/* <div className="col-xl-12 wow fadeInUp" data-wow-duration="1.5s">
                                <div className="tf__contact_map mt_100">
                                    <div style={{ overflow: 'hidden', maxWidth: '100%', width: 1200, height: 400 }}><div id="google-maps-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=3034+Fayetteville+St+Durham,+NC+27707&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="googl-ehtml" href="https://www.bootstrapskins.com/themes" id="get-data-for-embed-map">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#google-maps-display img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
            {/* <WhatsappIcon /> */}
            <br />
            <br />
            <Footer />



        </>
    )
}

export default ContactUs