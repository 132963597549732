import React from 'react'

const HomeResources = () => {
    return (
        <>
            <section className="tf__activities mt_100 xs_mt_95" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__heading_area tf__heading_area_left mb_20">
                                <h5>Al-Qalam Triangle Academy</h5>
                                <h2>Resources</h2>
                            </div>
                            <div className="tf__activities_text">
                                <div className="row">
                                    <div className="col-xl-6 col-sm-6">
                                        <br />
                                        <div className="tf__activities_item light_blue">
                                            <a href='./assets/images/AQTA-Tuition Fee - 2024-25.pdf' target="_blank">

                                                <span>
                                                    <i className="fal fa-book" />
                                                </span>
                                                <h3>Tuition and Fees</h3>
                                            </a>

                                        </div>

                                        <div className="tf__activities_item green">
                                            <a to='./assets/images/AQTA-Tuition Fee - 2024-25.pdf' target="_blank">

                                                <span>
                                                    <i className="fas fa-graduation-cap" />
                                                </span>
                                                <h3>Parent Handbook</h3></a>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-sm-6 xs_mt_0 mt_30 md_margin">
                                        <div className="tf__activities_item orange">
                                            <a href='./assets/images/AQTA - Supply List.pdf' target="_blank">

                                                <span>
                                                    <i className="far fa-university" />
                                                </span>
                                                <h3>School Supply List</h3></a>
                                        </div>
                                        <div className="tf__activities_item blue">
                                            <a to='./assets/images/AQTA - Supply List.pdf' target="_blank">
                                                <span>
                                                    <i className="fas fa-books-medical" />
                                                </span>
                                                <h3>Uniforms</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-9 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                            <div className="tf__activities_img">
                                <img src="./assets/images/img_5.webp" alt="activities" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}

export default HomeResources