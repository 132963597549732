import React from 'react'

const HomeFamilyPortal = () => {
    return (
        <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
            <section className="tf__about mt_250 xs_mt_195 mt-lg-5" >
                <div className="container">
                    <div className="tf__about_top wow fadeInUp" data-wow-duration="1.5s" style={{ background: 'url(./assets/images/about_top_bg.jpg)' }}>
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="tf__about_top_img">
                                    <img src="./assets/images/img_1.jpg" alt="about" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="tf__about_top_text">
                                    <div className="tf__about_top_text_center">
                                {/* <h5 style={{color:"white", textAlign:"center"}}>Family Portal</h5> */}

                                        <h4>View Student Portal</h4>
                                        <p>To facilitate a seamless connection between you and the school, we are pleased to provide you with access to our School Information System, Sycamore.</p>
                                    </div>
                                    <a href="https://schoolpro.azurewebsites.net/account/student" className="common_btn" target="_blank">Login Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeFamilyPortal